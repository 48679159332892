import { template as template_6563a4dc960d49f0b87f0ee10b71443c } from "@ember/template-compiler";
const FKText = template_6563a4dc960d49f0b87f0ee10b71443c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
