import { template as template_25d272ea9a3e469bbe25046ac116df3b } from "@ember/template-compiler";
const FKLabel = template_25d272ea9a3e469bbe25046ac116df3b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
